class CopyFields {
  fields: CopyField[];

  constructor(selector = '.copy-field') {
    this.fields = [];

    const fieldElements = document.querySelectorAll(selector);
    for (const fieldElement of fieldElements) {
      this.fields.push(new CopyField(fieldElement));
    }
  }
}

class CopyField {
  content: Element;
  button: Element;

  constructor(field: Element) {
    this.content = field.querySelector('.copy-content');
    this.button = field.querySelector('.copy-button');

    this.button.addEventListener('click', () => this.copy());
  }

  copy(): void {
    const data = this.content.textContent.trim();
    void navigator.clipboard.writeText(data);
  }
}

export { CopyField, CopyFields };
