class Sidebar {
  private sidebar: HTMLElement;
  private icon: HTMLElement;

  constructor() {
    this.sidebar = document.getElementById('sidebar');
    this.icon = document.getElementById('sidebarIcon');
    if (this.icon === null) {
      return;
    }

    this.icon.addEventListener('click', (event: MouseEvent) => {
      event.preventDefault();

      this.sidebar.classList.toggle('default');
    });
  }
}

export { Sidebar };
