import './style';
import 'bootstrap/dist/js/bootstrap.bundle';
import { CopyFields } from './copy/copy-fields';
import { Sidebar } from './sidebar/sidebar';
import Choices from 'choices.js';

new CopyFields();
new Sidebar();

const elements = document.querySelectorAll('.js-choice');
for (const element of elements) {
  if (!(element instanceof HTMLInputElement)) {
    continue;
  }

  new Choices(element, {
    removeItemButton: true,
    allowHTML: false,
    addItemText: (value) => {
      return `Eingabetaste drücken, um "${value}" hinzuzufügen`;
    },
  });
}
